.mint-container {
  --border-width: 2px;
  width: 40vw;
  background-color: rgba(var(--dark-rgb), 0.9);
  border-radius: 50px;
  color: var(--light);
  overflow-y: auto;
  padding: 50px;
  line-height: 45px;
  font-family: "Poppins", sans-serif;
  display: table-cell;
  vertical-align: middle;

  @media (min-width: 390px) and (max-width: 992px) {
    min-height: 65vh;
    width: 90vw;
  }

  @media only screen and (max-width: 390px) {
    min-height: 90vh;
    width: 90vw;
  }

  @media (min-width: 390px) and (max-width: 390px) {
    min-height: 70vh;
    width: 90vw;
  }
}
