@import url("https://fonts.googleapis.com/css2?family=Bangers&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter");

:root {
  --bg: #040014;

  --primary: #0eb168;
  --primary-rgb: 14, 177, 104;

  --secondary: #3fca8c;
  --secondary-rgb: 63, 202, 140;

  --dark: #191919;
  --dark-rgb: 25, 25, 25;

  --light: #ffffff;
  --light-rgb: 255, 255, 255;
}

* {
  cursor: default;
  font-family: "Inter", sans-serif;
}

body {
  overflow: hidden;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  text-align: center;
}

.main-btn {
  font-size: 22px;
  letter-spacing: 2px;
  font-family: "Bangers", sans-serif;
  background-color: var(--primary);
  color: var(--dark);
  border-radius: 50px;
  border: none;
  outline: none;
  padding: 5px 25px;
  transition: 0.15s ease-in;
}

.main-btn i,
.main-btn img {
  cursor: pointer;
}

.main-btn:disabled {
  cursor: not-allowed;
}

.main-btn:hover {
  filter: sepia(0.5);
}

.qty {
  font-size: 46px;
  font-weight: 800;
  font-family: "Bangers", sans-serif;
  letter-spacing: 4px;
}

.text-circle {
  background: transparent;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin: 5px;
  overflow: hidden;
  padding: 25px;
  position: relative;
}

.text-inside-circle {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.buy-qty-text {
  font-family: "Bangers", sans-serif;
  color: var(--primary);
  font-size: 28px;
}

.plus-minus-btn {
  outline: none;
  border: none;

  font-size: 36px;
  text-align: center;
  width: 50px;
  padding: 3px 15px;
  margin-bottom: 25px;
  background-color: var(--primary);
  cursor: pointer;
}

.plus-minus-btn:hover {
  filter: sepia(0.5);
}

.cost {
  font-family: "Bangers", sans-serif;
  letter-spacing: 1px;
  font-size: 32px;
  color: var(--light);
}

::-webkit-scrollbar {
  display: none;
}

.bear {
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: 20%;
}

.bear-1 {
  z-index: 3;
  background-position: bottom left;
  background-image: url("../assets/asset_1.png");
  animation: bearOneMove 2s infinite alternate;
}

.bear-2 {
  z-index: 2;
  background-position: bottom right;
  right: 0;
  background-image: url("../assets/asset_2.png");
  animation: bearTwoMove 1.8s infinite alternate;
}

@keyframes bearOneMove {
  0% {
    transform: translateY(90px) rotate(5deg);
  }

  100% {
    transform: translateY(200px) rotate(5deg);
  }

  0%,
  25% {
    background-image: url("../assets/asset_1.png");
  }

  100% {
    background-image: url("../assets/asset_3.png");
  }
}

@keyframes bearTwoMove {
  0% {
    transform: translateY(200px) rotate(-5deg);
  }

  100% {
    transform: translateY(25px) rotate(-5deg);
  }

  0%,
  25% {
    background-image: url("../assets/asset_2.png");
  }

  100% {
    background-image: url("../assets/asset_4.png");
  }
}

.river {
  animation: riverChangeColor 1s infinite alternate;
}

@keyframes riverChangeColor {
  0% {
    filter: saturate(1);
  }

  50% {
    filter: saturate(1.25);
  }

  100% {
    filter: saturate(1.5);
  }
}

#sky {
  overflow: hidden;
  position: relative;
  height: 600px;
}

.cloud {
  position: absolute;
  background-image: url("../assets/cloud.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 50%;
}

#cloud1 {
  top: 40px;
  left: 400px;
  -webkit-animation: movingclouds 25s linear infinite;
  -moz-animation: movingclouds 25s linear infinite;
  -o-animation: movingclouds 25s linear infinite;
}

#cloud2 {
  top: 10px;
  left: 600px;
  -webkit-animation: movingclouds 28s linear infinite;
  -moz-animation: movingclouds 28s linear infinite;
  -o-animation: movingclouds 28s linear infinite;
}

#cloud3 {
  top: 100px;
  left: 100px;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  opacity: 0.9;
  -webkit-animation: movingclouds 32s linear infinite;
  -moz-animation: movingclouds 32s linear infinite;
  -o-animation: movingclouds 32s linear infinite;
}

#cloud4 {
  top: -20px;
  left: 40px;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0.8;
  -webkit-animation: movingclouds 35s linear infinite;
  -moz-animation: movingclouds 35s linear infinite;
  -o-animation: movingclouds 35s linear infinite;
}

#cloud5 {
  top: 100px;
  left: 500px;
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0.7;
  -webkit-animation: movingclouds 40s linear infinite;
  -moz-animation: movingclouds 40s linear infinite;
  -o-animation: movingclouds 40s linear infinite;
}

#cloud6 {
  top: 20px;
  left: 200px;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0.5;
  -webkit-animation: movingclouds 45s linear infinite;
  -moz-animation: movingclouds 45s linear infinite;
  -o-animation: movingclouds 45s linear infinite;
}

#cloud7 {
  top: 92px;
  left: 700px;
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  -ms-transform: scale(0.4);
  transform: scale(0.4);
  opacity: 0.4;
  -webkit-animation: movingclouds 50s linear infinite;
  -moz-animation: movingclouds 50s linear infinite;
  -o-animation: movingclouds 50s linear infinite;
}

@keyframes movingclouds {
  0% {
    margin-left: 100%;
  }
  100% {
    margin-left: -150%;
  }
}

@-webkit-keyframes movingclouds {
  0% {
    margin-left: 100%;
  }
  100% {
    margin-left: -150%;
  }
}

@-moz-keyframes movingclouds {
  0% {
    margin-left: 100%;
  }
  100% {
    margin-left: -150%;
  }
}

@media only screen and (max-width: 600px) {
  .qty {
    font-size: 40px;
  }

  .main-btn {
    font-size: 18px;
    padding: 0px 15px;
  }

  .centered {
    position: absolute;
  }

  .bear {
    background-size: 35%;
  }

  .bear-1 {
    z-index: 10;
    animation: none;
  }

  .bear-2 {
    z-index: 10;
    animation: none;
  }
}
